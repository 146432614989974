<template>
    <SwPanel v-if="show" class="WebCookie">
        <h4 class="WebCookie_title sw-title">
            {{ $t('text_cookie_title') }}
        </h4>

        <div class="WebCookie_text sw-text">
            {{ $t('text_cookie_description') }}
        </div>

        <SwButton
            class="WebCookie_button"
            small
            @click="confirm"
        >
            {{ $t('text_cookie_confirm') }}
        </SwButton>
    </SwPanel>
</template>

<script>
export default {
  name: 'PageCookie',

  data: () => ({
    show: false
  }),

  mounted () {
    if (this.$cookies.get('showCookieModal') === undefined) {
      this.setCookieModal(true)
      this.show = true
    } else {
      this.show = this.$cookies.get('showCookieModal')
    }

    if (this.$store.state.admin) {
      this.show = false
    }
  },

  methods: {
    confirm () {
      this.setCookieModal(false)
      this.show = false
    },

    setCookieModal (value = false) {
      const currentDate = new Date()
      const expiresDate = currentDate.setMonth(currentDate.getMonth() + 6)

      this.$cookies.set('showCookieModal', value, {
        path: '/',
        expires: new Date(expiresDate)
      })
    }
  }
}
</script>

<style lang="less">
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";
@import (reference) "./assets/Web.less";

.WebCookie {
  &.sw-panel {
    position: fixed;
    left: 36px;
    bottom: 36px;
    max-width: 280px;
    text-align: center;
    align-items: center;
    padding: 24px !important;
    margin: 0 !important;
    box-shadow: 0 0 16px rgba(30, 34, 72, 0.16) !important;
    z-index: 1000;

    @media screen and (max-width: @bp-mobile-small) {
      left: 0;
      width: 100%;
      bottom: 0;
      max-width: none;
      padding: 24px 44px !important;
    }
  }

  &_title {
    margin: 0 0 8px;
  }

  &_text {
    margin-bottom: 16px;
    color: @grey-900;
    font-size: 12px;
    line-height: 14px;
  }

  &_button {
    @media screen and (max-width: @bp-mobile-small) {
      min-width: auto;
    }
  }
}
</style>
