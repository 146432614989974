<template>
    <div>
        <template v-if="errorFetch">
            <error />
        </template>

        <template v-else>
            <PageRoot v-if="keyId ? publicPageByKey : publicPage">
                <PageContent
                    :blocks="keyId ? publicPageByKey.publicPageBlocks : publicPage.publicPageBlocks"
                    :configs="keyId ? parsedConfigs(publicPageByKey.configs) : parsedConfigs(publicPage.configs)"
                    :is-mock="isMock"
                />

                <PageCookie />
            </PageRoot>
        </template>
    </div>
</template>

<script>
import PageCookie from '@@/website/components/PageCookie'
import PageContent from '@@/website/components/PageContent'
import PageRoot from '@@/website/components/PageRoot'
import error from '@@/website/layouts/error'

import {
  GET_META,
  GET_PAGE,
  GET_PAGE_BY_KEY
} from '@@/graphQL/web'
import CustomScriptMixin from '../components/common/CustomScriptMixin'

export default {
  components: {
    error,
    PageContent,
    PageCookie,
    PageRoot
  },

  mixins: [
    CustomScriptMixin
  ],

  layout: 'custom',

  props: {
    url: {
      type: String,
      default: null
    },

    keyId: {
      type: String,
      default: null
    },

    isMock: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      publicPage: null,
      publicPageByKey: null,
      errorFetch: false,
      metaBySlug: {
        title: '',
        description: ''
      }
    }
  },

  async fetch () {
    if (this.$store.state.admin) {
      try {
        if (this.keyId) {
          // @ts-ignore
          await this.$apolloProvider.defaultClient
            .query({
              query: GET_PAGE_BY_KEY,
              variables: {
                key: this.keyId,
                unpublished: true,
                siteCode: this.$store.state.siteCode
              },
              fetchPolicy: 'no-cache'
            })
            .then(({ data }) => {
              this.publicPageByKey = data.publicPageByKey
              if (!this.publicPageByKey) {
                this.errorFetch = true
              }
            })
        } else if (this.getUrl) {
          // @ts-ignore
          await this.$apolloProvider.defaultClient
            .query({
              query: GET_PAGE,
              variables: {
                url: this.getUrl,
                unpublished: true,
                siteCode: this.$store.state.siteCode
              },
              fetchPolicy: 'no-cache'
            })
            .then((res) => {
              this.publicPage = res.data.publicPage
              if (!this.publicPage) {
                this.errorFetch = true
              }
            })
        }
      } catch (e) {
        this.errorFetch = true
      }
    } else {
      try {
        if (this.keyId) {
          // @ts-ignore
          await this.$apolloProvider.defaultClient
            .query({
              query: GET_PAGE_BY_KEY,
              variables: {
                key: this.keyId
              },
              fetchPolicy: 'no-cache'
            })
            .then(({ data }) => {
              this.publicPageByKey = data.publicPageByKey
              if (!this.publicPageByKey) {
                this.errorFetch = true
              }
            })
        } else if (this.getUrl) {
          // @ts-ignore
          await this.$apolloProvider.defaultClient
            .query({
              query: GET_PAGE,
              variables: {
                url: this.getUrl
              },
              fetchPolicy: 'no-cache'
            })
            .then((res) => {
              this.publicPage = res.data.publicPage
              if (!this.publicPage) {
                this.errorFetch = true
              }
            })
        }
        if (!this.$parent.$metaInfo) {
          await this.$apolloProvider.defaultClient
            .query({
              query: GET_META,
              variables: {
                route: 'page',
                siteCode: this.$store.state.siteCode,
                slug: this.getUrl
              },
              fetchPolicy: 'no-cache'
            })
            .then((res) => {
              this.metaBySlug = res.data.metaBySlug
            })
        }
      } catch (e) {
        this.errorFetch = true
      }
    }
  },

  head () {
    const isSitePublished = this.$store.state.settings.isPublished
    if (isSitePublished) {
      return {
        title: this.$parent.$metaInfo?.title || this.metaBySlug?.title,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: this.$parent.$metaInfo?.description || this.metaBySlug?.description
          }
        ],
        ...this.buildMetaForCustomScripts()
      }
    }

    if (!this.$parent.$metaInfo?.title) {
      return {
        title: this.metaBySlug?.title,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: this.metaBySlug?.description
          }
        ]
      }
    }
  },

  computed: {
    getUrl () {
      if (this.url) {
        return this.url
      }
      return this.$route.name === 'index' || this.$route.name === 'siteCode-preview'
        ? ''
        : this.$route.params.pathMatch?.split('/').filter(e => e)[
          this.$route.params.pathMatch?.split('/').filter(e => e).length - 1
        ]
    }
  },

  methods: {
    parsedConfigs (cfg) {
      return !cfg ? {} : JSON.parse(cfg)
    }
  }
}
</script>
