<template>
    <SwRoot :class="$style['root']">
        <UiLoader v-if="loading" fixed overlay />
        <SwContainer v-bind="{ ...$attrs, ...$props }" fluid pa0>
            <slot />
        </SwContainer>
    </SwRoot>
</template>

<script>
export default {
  name: 'PageRoot',

  props: {
    bgColor: {
      type: String,
      default: '#ffffff'
    },

    bgOpacity: {
      type: String,
      default: '100%'
    }
  },

  data: () => ({
    loading: false
  }),

  watch: {
    '$store.state.isLoading' (loading) {
      if (loading) {
        this.$nuxt.$loading.start()
        this.loading = true
      } else {
        setTimeout(() => {
          this.loading = false
          this.$nuxt.$loading.finish()
        }, 300)
      }
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.root {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100vh;
  padding-top: 0;
  margin: auto;
  @media screen and (max-width: @bp-tablet) {
    min-height: auto;
  }

  & > :global(.sw-container) {
    min-height: calc(100vh - 200px);
  }
}
</style>
