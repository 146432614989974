<template>
    <div :class="$style['platform-copyright']">
        <template v-if="isSimla">
            <a
                v-if="$store.state.settings.system_language === 'ES'"
                href="//www.simla.com/es?utm_source=simlacom&utm_medium=simlacom_sites&utm_campaign=logo_footer"
                target="_blank"
            >
                <SvgSpritePoweredBySimla />
            </a>

            <a
                v-else
                href="//www.simla.com/?utm_source=simlacom&utm_medium=simlacom_sites&utm_campaign=logo_footer"
                target="_blank"
            >
                <SvgSpritePoweredBySimla />
            </a>
        </template>

        <a
            v-else
            :class="$style['platform-copyright__link']"
            href="//www.retailcrm.ru/?utm_source=retailcrm&utm_medium=retailcrm_sites&utm_campaign=logo_footer"
            target="_blank"
        >
            {{ $t('created_on_platform') }}
            <img
                :class="$style['platform-copyright__logo']"
                src="//s3-s1.retailcrm.tech/eu-central-1/retailcrm-static/branding/retailcrm/logo/logo_horiz_white.svg"
                alt="retailcrm"
            >
        </a>
    </div>
</template>

<script>
import SvgSpritePoweredBySimla from './sprites/powered-by-simla.svg'

const THEMES = {
  RETAILCRM: 'retailcrm',
  SIMLA: 'simla'
}

export default {
  name: 'PlatformCopyright',

  components: {
    SvgSpritePoweredBySimla
  },

  computed: {
    isSimla () {
      return this.$store.state.settings.copyrightLogo === THEMES.SIMLA
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "../../assets/Web.less";

.platform-copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: black;
  margin: 0 auto;
  position: relative;

  & a {
    padding: 0 16px;
    margin: 16px 0;
    z-index: 12;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--sw-font-texts);
    font-size: 14px;
    line-height: 20px;
    color: fade(#fff, 60%);
    transition: .2s linear;

    &:hover {
      color: white;
      color: fade(#fff, 80%);
    }
  }

  &__logo {
    height: 16px;
    margin-left: 8px;
  }
}
</style>
