<template>
    <div
        :class="$style['content']"
        :style="{
            backgroundColor: ['/checkout', '/cart'].includes($route.path) || $route.path.startsWith('/user')
                ? '#f4f6f8'
                : $route.path.startsWith('/catalog')
                    ? '#ffffff'
                    : '#' + $simlaweb.settings.colors.background
        }"
    >
        <div v-if="showOverlay" :class="$style['content__overlay']" />

        <template v-for="(block, index) in blocks">
            <BlockHeader
                v-if="block.key === 'header'"
                :key="index+'-'+block.pivotId"
                :style-key="block.pageBlockStyleKey"
                :config="parse(block.configs)"
                :fixed="$store.state.settings.can_scrolling_header"
            />

            <BlockBreadcrumbs
                v-else-if="block.key === 'breadcrumbs'"
                :key="index+'-'+block.pivotId"
                :style-key="block.pageBlockStyleKey"
                :config="parse(block.configs)"
            />

            <BlockCart
                v-else-if="block.key === 'added_products'"
                :key="index+'-'+block.pivotId"
                :style-key="block.pageBlockStyleKey"
                :config="parse(block.configs)"
                :is-mock="isMock"
            />

            <BlockCatalog
                v-else-if="block.key === 'list_of_products'"
                :key="index+'-'+block.pivotId"
                :style-key="block.pageBlockStyleKey"
                :config="parse(block.configs)"
                :title="$t('catalog')"
            />

            <BlockCatalog
                v-else-if="block.key === 'product_search'"
                :key="index+'-'+block.pivotId"
                :style-key="block.pageBlockStyleKey"
                :config="parse(block.configs)"
                :title="$t('search')"
                is-search
            />

            <BlockContacts
                v-else-if="block.key === 'contacts'"
                :key="index+'-'+block.pivotId"
                :pivot-id="block.pivotId"
                :style-key="block.pageBlockStyleKey"
                :config="parse(block.configs)"
            />

            <BlockCover
                v-else-if="block.key === 'cover_art'"
                :key="index+'-'+block.pivotId"
                :pivot-id="block.pivotId"
                :style-key="block.pageBlockStyleKey"
                :config="parse(block.configs)"
            />

            <BlockFeedbackForm
                v-else-if="block.key === 'forms'"
                :key="index+'-'+block.pivotId"
                :pivot-id="block.pivotId"
                :style-key="block.pageBlockStyleKey"
                :config="parse(block.configs)"
            />

            <BlockMedia
                v-else-if="block.key === 'media'"
                :key="index+'-'+block.pivotId"
                :style-key="block.pageBlockStyleKey"
                :config="parse(block.configs)"
            />

            <BlockOrderCheckout
                v-else-if="block.key === 'checkout'"
                :key="index+'-'+block.pivotId"
                :style-key="block.pageBlockStyleKey"
                :config="parse(block.configs)"
                :is-mock="isMock"
            />

            <BlockOrderProcessed
                v-else-if="block.key === 'user_order_processed'"
                :key="index+'-'+block.pivotId"
                :style-key="block.pageBlockStyleKey"
                :config="parse(block.configs)"
                :success="$route.params.success || $route.query.success === 'true'"
            />

            <BlockProduct
                v-else-if="block.key === 'product_information'"
                :key="index+'-'+block.pivotId"
                :style-key="block.pageBlockStyleKey"
                :config="parse(block.configs)"
                :is-mock="isMock"
            />

            <BlockProductList
                v-else-if="block.key === 'goods'"
                :key="index+'-'+block.pivotId"
                :pivot-id="block.pivotId"
                :style-key="block.pageBlockStyleKey"
                :config="parse(block.configs)"
            />

            <BlockText
                v-else-if="block.key === 'text'"
                :key="index+'-'+block.pivotId"
                :style-key="block.pageBlockStyleKey"
                :config="parse(block.configs)"
            />

            <BlockUserAccount
                v-else-if="block.key === 'user_personal_account'"
                :key="index+'-'+block.pivotId"
                :style-key="block.pageBlockStyleKey"
                :config="parse(block.configs)"
            />

            <BlockUserOrders
                v-else-if="block.key === 'user_personal_orders'"
                :key="index+'-'+block.pivotId"
                :style-key="block.pageBlockStyleKey"
                :config="parse(block.configs)"
            />

            <BlockUserPasswordRecovery
                v-else-if="block.key === 'user_password_recovery'"
                :key="index+'-'+block.pivotId"
                :style-key="block.pageBlockStyleKey"
                :config="parse(block.configs)"
            />

            <BlockFooter
                v-else-if="block.key === 'footer'"
                :key="index+'-'+block.pivotId"
                :variant="block.pageBlockStyleKey"
                :config="parse(block.configs)"
            />
        </template>

        <PlatformCopyright />

        <ModalRegistration
            :opened="$store.getters['modals/opened']('registration')"
            :config="configs.modal_registration_and_authorization"
        />
    </div>
</template>

<script>
import BlockBreadcrumbs from '@@/website/components/blocks/BlockBreadcrumbs'
import PlatformCopyright from '@@/website/components/PlatformCopyright'

export default {
  name: 'PageContent',

  components: {
    BlockBreadcrumbs,
    BlockCart: () => import('./blocks/BlockCart'),
    BlockCatalog: () => import('./blocks/BlockCatalog'),
    BlockContacts: () => import('./blocks/BlockContacts'),
    BlockCover: () => import('./blocks/BlockCover'),
    BlockFeedbackForm: () => import('./blocks/BlockFeedbackForm'),
    BlockFooter: () => import('./blocks/BlockFooter'),
    BlockHeader: () => import('./blocks/BlockHeader'),
    BlockMedia: () => import('./blocks/BlockMedia'),
    BlockOrderCheckout: () => import('./blocks/BlockOrderCheckout'),
    BlockOrderProcessed: () => import('./blocks/BlockOrderProcessed'),
    BlockProduct: () => import('./blocks/BlockProduct'),
    BlockProductList: () => import('./blocks/BlockProductList'),
    BlockText: () => import('@@/website/components/blocks/BlockText'),
    BlockUserAccount: () => import('./blocks/BlockUserAccount'),
    BlockUserOrders: () => import('./blocks/BlockUserOrders'),
    BlockUserPasswordRecovery: () => import('./blocks/BlockUserPasswordRecovery'),
    ModalRegistration: () => import('./modals/ModalRegistration'),
    PlatformCopyright
  },

  props: {
    blocks: {
      type: Array,
      default: () => []
    },

    configs: {
      type: Object,
      default: () => ({})
    },

    isMock: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    showOverlay () {
      const inConstructor = this.$store.state.admin && !this.$route.name.includes('preview')

      return process.client
        ? inConstructor || window
            ? window.frameElement
            : false
        : inConstructor
    }
  },

  methods: {
    parse (configurationJson) {
      return !configurationJson
        ? {}
        : JSON.parse(configurationJson)
    }
  }
}
</script>

<style lang="less" module>
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  position: absolute;
  left: 0;

  &__overlay:before {
    content: "";
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;
  }
}
</style>
