<template>
    <div
        :key="update"
        :class="$style['breadcrumbs']"
        :style="{
            background: hexToRgba(config.background_color_all, config.background_opacity_all),
        }"
    >
        <SwContainer :class="$style['breadcrumbs__container']" py0>
            <div :class="$style['breadcrumbs__content']">
                <div :class="$style['breadcrumbs__list']">
                    <div
                        v-for="(link, index) in breadcrumbs"
                        :key="index"
                        :class="$style['breadcrumbs__item']"
                    >
                        <SwLink
                            :to="
                                index === 0
                                    ? { name: 'index' }
                                    : index === 1
                                        ? { name: 'catalog' }
                                        : { name: 'catalog-product_group_slug', params: { product_group_slug: link.slug } }
                            "
                            :inverted="inverted"
                            :disabled="index === breadcrumbs.length - 1"
                        >
                            {{ link.name }}
                        </SwLink>

                        <span
                            v-if="breadcrumbs.length - 1 > index"
                            :class="$style['breadcrumbs__separator']"
                        >
                            /
                        </span>
                    </div>
                </div>
            </div>
        </SwContainer>
    </div>
</template>

<script>
import {
  GET_BREADCRUMBS,
  GET_PRODUCT_BREADCRUMBS
} from '@@/graphQL/web'

export default {
  name: 'BlockBreadcrumbs',

  props: {
    config: {
      type: Object,
      default: () => ({
        background_color_all: '13142A',
        background_opacity_all: '95%',
        inverted_font_color_header_all: false
      })
    }
  },

  data: () => {
    return {
      breadcrumbs: null,
      update: 0
    }
  },

  computed: {
    /**
     * @returns {boolean}
     */
    inverted () {
      return this.config.inverted_font_color_header_all
    }
  },

  watch: {
    '$route.params.slug' () {
      this.$nextTick(() => {
        this.update++
      })
    },

    '$route.params.product_group_slug' () {
      this.$nextTick(() => {
        this.update++
      })
    }
  },

  async mounted () {
    try {
      if (!this.$route.name.includes('catalog-details-slug')) {
        await this.$apolloProvider.defaultClient
          .query({
            query: GET_BREADCRUMBS,
            variables: {
              slug: this.$route.params.product_group_slug || ''
            }
          })
          .then((res) => {
            this.breadcrumbs = res.data.breadcrumbsCatalog
          })
      } else {
        // @ts-ignore
        await this.$apolloProvider.defaultClient
          .query({
            query: GET_PRODUCT_BREADCRUMBS,
            variables: {
              slug: this.$route.params.slug || '',
              product_group_slug: this.$route.params.product_group_slug || ''
            }
          })
          .then((res) => {
            this.breadcrumbs = res.data.breadcrumbsProduct
          })
      }
    } catch (e) {
      console.log(e)
      this.$nuxt.error({ statusCode: 404 })
    }
  },

  methods: {
    hexToRgba (hexColor, opacity) {
      hexColor = hexColor.replace('#', '')
      opacity = opacity.replace('%', '')
      const r = parseInt(hexColor.substring(0, 2), 16)
      const g = parseInt(hexColor.substring(2, 4), 16)
      const b = parseInt(hexColor.substring(4, 6), 16)
      const a = opacity / 100
      return `rgba( ${r}, ${g}, ${b}, ${a} )`
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";
@import (reference) "./assets/Web.less";

.breadcrumbs {
  display: flex;
  min-height: 36px;

  &__container {
    background: rgba(20, 20, 42, 0.05);
    overflow: hidden;
  }

  &__content {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: @bp-tablet;
    box-sizing: border-box;
    padding-top: 8px;
    padding-bottom: 8px;
    margin: auto;
    position: relative;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    @media screen and (max-width: @bp-mobile-miny) {
      padding-bottom: 10px;
      top: 5px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  &__separator {
    font-size: 10px;
    color: @grey-600;
    margin: auto 10px;
  }
}
</style>
